var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "tooltip-demo" } },
    [
      _c("tooltip-default"),
      _c("tooltip-position"),
      _c("tooltip-delay"),
      _c("tooltip-color"),
      _c("tooltip-title"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }