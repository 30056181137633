import { render, staticRenderFns } from "./TooltipPosition.vue?vue&type=template&id=7615aa24&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7615aa24')) {
      api.createRecord('7615aa24', component.options)
    } else {
      api.reload('7615aa24', component.options)
    }
    module.hot.accept("./TooltipPosition.vue?vue&type=template&id=7615aa24&", function () {
      api.rerender('7615aa24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/vuesax/tooltip/TooltipPosition.vue"
export default component.exports