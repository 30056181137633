var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Title", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "If necessary, you can add a title to the tooltip with the property "
        ),
        _c("code", [_vm._v("title")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vx-tooltip",
            {
              attrs: {
                title: "Are you sure?",
                color: "warning",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras scelerisque non neque sed aliquet.",
              },
            },
            [
              _c("vs-button", { attrs: { color: "warning", type: "flat" } }, [
                _vm._v("Title Tooltip"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vx-tooltip\n    title="Are you sure?"\n    color="warning"\n    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras scelerisque non neque sed aliquet.">\n\n    <vs-button color="warning" type="flat">Title Tooltip</vs-button>\n  </vx-tooltip>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }