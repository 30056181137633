var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Position", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the position at which the tooltip appears with the property "
        ),
        _c("code", [_vm._v("position")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip position Top", position: "top" } },
            [_c("vs-button", { attrs: { type: "gradient" } }, [_vm._v("Top")])],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip position Bottom", position: "bottom" } },
            [
              _c("vs-button", { attrs: { type: "gradient" } }, [
                _vm._v("Bottom"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip position Left", position: "left" } },
            [
              _c("vs-button", { attrs: { type: "gradient" } }, [
                _vm._v("Left"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip position Right", position: "right" } },
            [
              _c("vs-button", { attrs: { type: "gradient" } }, [
                _vm._v("Right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vx-tooltip text="Tooltip position Top" position="top">\n    <vs-button type="gradient">Top</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip text="Tooltip position Bottom" position="bottom">\n    <vs-button type="gradient">Bottom</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip text="Tooltip position Left" position="left">\n    <vs-button type="gradient">Left</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip text="Tooltip position Right" position="right">\n    <vs-button type="gradient">Right</vs-button>\n  </vx-tooltip>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }