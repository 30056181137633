var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "To change the color of the tooltip, use the property color. You are able to use the Main Colors or "
        ),
        _c("strong", [_vm._v("RGB")]),
        _vm._v(" and "),
        _c("strong", [_vm._v("HEX")]),
        _vm._v(" colors"),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip default" } },
            [
              _c("vs-button", { attrs: { type: "gradient" } }, [
                _vm._v("Default Color"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "primary", text: "Tooltip Primary" } },
            [
              _c("vs-button", { attrs: { type: "border" } }, [
                _vm._v("Color primary"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "success", text: "Tooltip Success" } },
            [
              _c("vs-button", { attrs: { color: "success", type: "border" } }, [
                _vm._v("Color success"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "danger", text: "Tooltip Danger" } },
            [
              _c("vs-button", { attrs: { color: "danger", type: "border" } }, [
                _vm._v("Color danger"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "warning", text: "Tooltip Warning" } },
            [
              _c("vs-button", { attrs: { color: "warning", type: "border" } }, [
                _vm._v("Color warning"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "dark", text: "Tooltip Dark" } },
            [
              _c("vs-button", { attrs: { color: "dark", type: "border" } }, [
                _vm._v("Color dark"),
              ]),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "rgb(42, 207, 133)", text: "Tooltip RGB" } },
            [
              _c(
                "vs-button",
                { attrs: { color: "rgb(42, 207, 133)", type: "border" } },
                [_vm._v("Color RGB")]
              ),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { color: "#4a0d6b", text: "Tooltip HEX" } },
            [
              _c("vs-button", { attrs: { color: "#4a0d6b", type: "border" } }, [
                _vm._v("Color HEX"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vx-tooltip text="Tooltip default">\n    <vs-button type="gradient">Default Color</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="primary" text="Tooltip Primary">\n    <vs-button type="border">Color primary</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="success" text="Tooltip Success">\n    <vs-button color="success" type="border">Color success</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="danger" text="Tooltip Danger">\n    <vs-button color="danger" type="border">Color danger</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="warning" text="Tooltip Warning">\n    <vs-button color="warning" type="border">Color warning</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="dark" text="Tooltip Dark">\n    <vs-button color="dark" type="border">Color dark</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="rgb(42, 207, 133)" text="Tooltip RGB">\n    <vs-button color="rgb(42, 207, 133)" type="border">Color RGB</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip color="#4a0d6b" text="Tooltip HEX">\n    <vs-button color="#4a0d6b" type="border">Color HEX</vs-button>\n  </vx-tooltip>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }