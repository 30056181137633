var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To add a tooltip we have the component "),
        _c("code", [_vm._v("vx-tooltip")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip Default" } },
            [
              _c("vs-switch", {
                model: {
                  value: _vm.switch1,
                  callback: function ($$v) {
                    _vm.switch1 = $$v
                  },
                  expression: "switch1",
                },
              }),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip Default" } },
            [
              _c("vs-input-number", {
                model: {
                  value: _vm.number1,
                  callback: function ($$v) {
                    _vm.number1 = $$v
                  },
                  expression: "number1",
                },
              }),
            ],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip Default" } },
            [
              _c("vs-button", { attrs: { color: "primary", type: "border" } }, [
                _vm._v("Button"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vx-tooltip text="Tooltip Default">\n    <vs-switch v-model="switch1" />\n  </vx-tooltip>\n\n  <vx-tooltip text="Tooltip Default">\n    <vs-input-number v-model="number1" />\n  </vx-tooltip>\n\n  <vx-tooltip text="Tooltip Default">\n    <vs-button color="primary" type="border">Button</vs-button>\n  </vx-tooltip>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      switch1:true,\n      number1:10,\n    }\n  }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }