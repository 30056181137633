var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Delay", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the delay in appearing the tooltip with the property "
        ),
        _c("code", [_vm._v("delay")]),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c(
            "vx-tooltip",
            { attrs: { text: "Tooltip default delay" } },
            [_c("vs-button", [_vm._v("Delay default")])],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { delay: ".5s", text: "Tooltip delay 0.5s" } },
            [_c("vs-button", [_vm._v("Delay 0.5s")])],
            1
          ),
          _c(
            "vx-tooltip",
            { attrs: { delay: "2s", text: "Tooltip delay 2s" } },
            [_c("vs-button", [_vm._v("Delay 2s")])],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <vx-tooltip text="Tooltip default delay">\n    <vs-button>Delay default</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip delay=".5s" text="Tooltip delay 0.5s">\n    <vs-button>Delay 0.5s</vs-button>\n  </vx-tooltip>\n\n  <vx-tooltip delay="2s" text="Tooltip delay 2s">\n    <vs-button>Delay 2s</vs-button>\n  </vx-tooltip>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }